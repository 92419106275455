import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import "./App.css";
import GreenSquares from "./components/GreenSquares";
import StravaConnect from "./components/StravaConnect";

function groupData(data: any): { date: string; count: number }[] {
  const groupedData: { [key: string]: number } = {};
  data.forEach((activity: any) => {
    // NOTE: startDate isin UTC
    const date = new Date(activity.startDate);
    const localDay = date.toLocaleDateString("en-CA");

    if (!groupedData[localDay]) {
      groupedData[localDay] = 0;
    }
    groupedData[localDay] += 1;
  });

  return Object.entries(groupedData).map(([date, count]) => ({
    date,
    count,
  }));
}

function AthleteRoute() {
  const [contributions, setContributions] = useState<
    {
      date: string;
      count: number;
    }[]
  >([]);
  const { id } = useParams();

  useEffect(() => {
    const hostname = "api.greensquares.me";
    const apiUrl = `https://${hostname}/strava/athlete/${id}/activities`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((wrapedResponse) => {
        setContributions(groupData(wrapedResponse.data));
      })
      .catch((error) => console.error("Error fetching activities:", error));
  }, [id]);

  return (
    <div className="athlete-content">
      <h1>Stay active, fill this grid 💪</h1>
      <div className="green-squares-wrapper">
        <div className="green-squares-container">
          <GreenSquares contributions={contributions} />
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <div className="scrollable-container">
          <Routes>
            <Route path="/athlete/:id" element={<AthleteRoute />} />
            <Route path="*" element={<StravaConnect />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
