import React from "react";
import { Tooltip } from "react-tooltip";

interface SquareProps {
  contributions: number;
  date: string;
  getColor: (count: number) => string;
  opacity: number;
}

type GitHubGrid = Array<
  Array<{ count: number; date: string; opacity: number }>
>;

type MonthLabels = { weekIndex: number; month: string }[];

const Square: React.FC<SquareProps> = ({
  contributions,
  date,
  getColor,
  opacity,
}) => {
  const backgroundColor = getColor(contributions);
  const borderColor = contributions === 0 ? "#d0d7de" : backgroundColor;

  const formattedDate = date
    ? new Date(date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
        timeZone: "UTC",
      })
    : "";

  const tooltipContent = date
    ? `${contributions} ${
        contributions === 1 ? "activity" : "activities"
      } on ${formattedDate}`
    : "";

  return (
    <td
      data-tooltip-id="contribution-tooltip"
      data-tooltip-content={tooltipContent}
      style={{
        width: "10px",
        height: "10px",
        backgroundColor,
        border: `1px solid ${borderColor}`,
        borderRadius: "2px",
        // empty squares for the days that are not in the current year
        opacity,
      }}
    />
  );
};

interface Contribution {
  date: string;
  count: number;
}

interface GreenSquaresProps {
  contributions: Contribution[];
}

function getQuartiles(sortedContributions: Contribution[]) {
  const counts = sortedContributions
    .map((c) => c.count)
    .filter((count) => count > 0);
  const minCount = Math.min(...counts);
  const maxCount = Math.max(...counts);
  const step = (maxCount - minCount) / 4;
  const q1 = minCount + step;
  const q2 = minCount + 2 * step;
  const q3 = minCount + 3 * step;
  const getColor = (count: number) => {
    if (count === 0) return "#ebedf0";
    if (count <= q1) return "#9be9a8";
    if (count <= q2) return "#40c463";
    if (count <= q3) return "#30a14e";
    return "#216e39";
  };
  return { q1, q2, q3, getColor };
}

/**
 * I observed extremely weird behavior for this in the past, so I have
 * standardized it by just taking the name of the day
 */
const dayIndicies: { [key: string]: number } = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

function getRow(date: Date): number {
  const nameOfDay = date.toString().substring(0, 3);
  return dayIndicies[nameOfDay];
}

function generateGridAndMonths(sortedContributions: Contribution[]): {
  grid: GitHubGrid;
  monthLabels: MonthLabels;
} {
  const contributionsMap: { [key: string]: number } = {};
  sortedContributions.forEach((contribution) => {
    contributionsMap[contribution.date] = contribution.count;
  });

  // 53 columns because the final week is always "in progress"
  // The 1st column should also have items missing (except for when today is
  // Sunday, in which case it is empty)
  const grid = Array(7)
    .fill(null)
    .map(() => Array(53).fill({ count: 0, date: "", opacity: 0 }));

  const monthLabels = [];

  let column = grid[0].length - 1;
  // 364 because it divides evenly into 52 weeks
  for (let i = 0; i < 364; i++) {
    // Due to daylight savings time, we cannot just subtract 24 hours from now,
    // since if it is 11pm we might subtract 24 hours but then get pushed
    // forward by 1 hour, meaning we duplicate the current date.
    const date = new Date();
    date.setDate(date.getDate() - i);
    const dateString = date.toLocaleDateString("en-CA");
    const row = getRow(date);

    grid[row][column] = {
      count: contributionsMap[dateString] || 0,
      date: dateString,
      opacity: 1,
    };

    if (date.getDate() === 1) {
      monthLabels.push({
        weekIndex: column,
        month: date.toLocaleString("default", {
          month: "short",
        }),
      });
    }

    // If we are at Saturday (top row), reduce the column by 1 to move
    // backwards.
    if (row === 0) {
      column--;
    }
  }

  monthLabels.reverse();

  return { grid, monthLabels };
}

const GreenSquares: React.FC<GreenSquaresProps> = ({
  contributions,
}: {
  contributions: Contribution[];
}) => {
  const sortedContributions = [...contributions].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const { getColor } = getQuartiles(sortedContributions);
  const { grid, monthLabels } = generateGridAndMonths(sortedContributions);

  return (
    <>
      <table style={{ borderSpacing: "3px", minWidth: "910px" }}>
        <tbody>
          {grid.map((week, dayIndex) => (
            <tr key={dayIndex}>
              {week.map((day, weekIndex) => (
                <Square
                  key={`${dayIndex}-${weekIndex}`}
                  contributions={day.count}
                  date={day.date}
                  getColor={getColor}
                  opacity={day.opacity}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{
          display: "flex",
          minWidth: "910px",
          position: "relative",
          height: "20px",
          marginTop: "4px",
        }}
      >
        {monthLabels.map(({ weekIndex, month }) => (
          <div
            key={weekIndex}
            style={{
              position: "absolute",
              left: `${Number(weekIndex) * 17}px`,
              fontSize: "12px",
            }}
          >
            {month}
          </div>
        ))}
      </div>
      <Tooltip id="contribution-tooltip" />
    </>
  );
};

export default GreenSquares;
